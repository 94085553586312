<messages>["./Host"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <div>
    <base-layout mw3>
      <v-col cols="12">
        <v-alert
          v-model="isSuccessAlertVisible"
          type="success"
          dismissible>
          {{ $t ('edit.success') }}
          <template #link>
            <router-link
              :to="{name: 'host.view', params: { registry, handle }}"
              v-text="handle"/>
          </template>
        </v-alert>
      </v-col>
    </base-layout>

    <v-alert
      id="invalidDataAlert"
      v-t="'general.invalid.form'"
      type="error"
      :value="isInvalidDataAlertVisible"/>

    <host-create-update
      :is-create="false"
      :host-data="{host: {
        handle,
        registryType: registry
      }}"
      @unsavedChanges="setUnsavedChanges"
      @success="onSuccess"
      @dataValidityChanged="onDataValidityChanged"
      @cancel="back"/>
  </div>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import goTo from 'vuetify/lib/services/goto'

  import unsavedChangesHelper from '@/app/core/mixins/UnsavedChangesHelper'
  import BaseLayout from '@/app/core/components/BaseLayout'

  import HostCreateUpdate from './components/HostCreateUpdate'
  import {mapMutations} from 'vuex'

  export default {
    name: 'HostEdit',

    components: {
      BaseLayout,
      HostCreateUpdate
    },

    mixins: [unsavedChangesHelper],

    props: {
      handle: {type: String, required: true},
      registry: {type: String, required: true}
    },

    data () {
      return {
        isSuccessAlertVisible: false,
        isInvalidDataAlertVisible: false
      }
    },

    methods: {

      ...mapMutations ('notification', ['addEvent']),

      onSuccess () {
        this.addEvent ({
          type: 'success',
          message: this.$t ('edit.success', {link: this.handle}),
          objects: [{
            name: this.handle,
            link: {
              name: 'host.view',
              params: {registry: this.registry, handle: this.handle}
            }
          }]
        })

        this.isSuccessAlertVisible = true
      },

      onDataValidityChanged (isValid) {
        console.warn ('onDataValidityChanged', isValid)
        this.isInvalidDataAlertVisible = !isValid
        if (this.isInvalidDataAlertVisible) this.scrollToAlert ()
      },

      back () {
        this.$router.back ()
      },

      scrollToAlert () {
        goTo ('#invalidDataAlert')
      }
    }
  }
</script>
