<messages>["../Host"]</messages>

<template>
  <registry-object-create-update
    v-if="!isCreate ? loaded : true"
    type="host"
    :is-create="isCreate"
    :name="hostObject.host.domainName || hostObject.host.handle"
    :registry-id="registryId"
    :client-id="clientId"
    :show-buttons="showContent"
    :response="orderProcessingResponse"
    :is-loading="isLoading"
    @registrySelected="onRegistrySelected"
    @loaded="onCreateUpdateComponentLoaded"
    @clientSelected="onClientSelected"
    @submit="onSubmit"
    @reset="onReset">
    <template
      v-if="showContent"
      #content>
      <v-card>
        <v-container fluid>
          <v-row>
            <v-col cols="6" class="py-0">
              <v-text-field
                v-model.trim="hostObject.host.domainName"
                :label="$t ('create.label.name')"/>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-text-field
                v-model.trim="hostObject.host.maintainer"
                spellcheck="false"
                :label="$t ('create.label.maintainer')"/>
            </v-col>
            <v-col cols="12" class="py-0">
              <provider-chain
                ref="providerchainComponent"
                v-model="chainModel"
                :edit="hostObject.editProviderChain"
                @edit="hostObject.editProviderChain = $event"/>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-switch
                v-model="isChildHost"
                :label="$t ('create.label.configureips')"/>
            </v-col>
            <v-col
              v-if="isChildHost"
              cols="12"
              class="py-0">
              <multi-ip-input ref="ipInput" v-model="hostObject.host.ips"/>
            </v-col>
            <v-col cols="12" class="py-0">
              <add-on
                v-model="hostObject.addOnFieldData"
                :v="$v.hostObject.addOnFieldData"
                @input="populateAddOnFieldData ($event)"/>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </template>
  </registry-object-create-update>
</template>

<script>
  import AddOn from '@/app/core/components/RegistryObject/AddOn'
  import AddOnValidation
    from '@/app/core/components/RegistryObject/AddOnValidation'

  import {getDefaultHostData, DefaultEditProviderChain, getAddOnFieldData} from '../constants'

  import _cloneDeep from 'lodash/cloneDeep'
  import _isEqual from 'lodash/isEqual'

  import {providerChain} from '@/app/validators'
  import validationMixins from '@/app/core/mixins/ValidationHelper'

  import {mapActions, mapGetters, mapMutations} from 'vuex'

  import ProviderChain from '@/app/core/components/RegistryObject/ProviderChain'
  import MultiIpInput from '@/app/core/components/Inputs/MultiIpInput'

  import RegistryObjectCreateUpdate
    from '@/app/core/components/RegistryObject/RegistryObjectCreateUpdate'

  import {keyValueArrayToObject, objectToKeyValueArray} from '@/app/utils/array'
  import {removePrefix} from '@/app/utils/string'
  import errorTranslator from '@/app/services/errortranslator'

  import {isUpdateProhibited}
    from '@/app/core/components/RegistryObject/StatesDialog'

  export default {
    name: 'HostCreateUpdate',

    components: {
      AddOn,
      RegistryObjectCreateUpdate,
      MultiIpInput,
      ProviderChain
    },
    mixins: [validationMixins],

    props: {
      isCreate: {type: Boolean, default: false},
      hostData: {
        type: Object,
        default: undefined
      }
    },

    validations: {
      hostObject: {
        host: {
          providerChainSpec: {providerChain}
        },
        addOnFieldData: AddOnValidation
      }
    },

    data () {
      return {
        hostObject: {
          host: {
            ips: [],
            addOnFields: {}
          },
          addOnFieldData: [],
          editProviderChain: false
        },
        orderProcessingResponse: null,
        isLoading: false,
        isLoadingHost: false,
        loaded: false,
        isChildHost: false,
        savedIps: [],
        defaultHost: null
      }
    },

    computed: {
      ...mapGetters ({
        hostCreateData: 'create/getHostCreateData'
      }),

      chainModel: {
        get () {
          return {
            clientId: this.hostObject.host.clientId,
            spec: this.hostObject.host.providerChainSpec,
            type: this.hostObject.host.providerChainType
          }
        },
        set (newValue) {
          this.hostObject.host.providerChainSpec = newValue.spec
          this.hostObject.host.providerChainType = newValue.type
        }
      },

      addOnFields: {
        get () {
          return this.hostObject?.addOnFieldData || []
        },

        set (newValue) {
          if (Array.isArray (newValue)) {
            this.hostObject.host.addOnFields = keyValueArrayToObject (newValue)
          }
        }
      },

      showContent () {
        return !!(this.registryId &&
          this.clientId)
      },

      validData () {
        return !this.$v.$invalid && !this.$refs.ipInput?.hasErrors ()
      },

      clientId () {
        return this.hostObject.host.clientId
          ? this.hostObject.host.clientId
          : this.hostObject.host.memberID
      },

      registryId () {
        return this.hostObject.host.registryId
          ? this.hostObject.host.registryId
          : this.hostObject.host.registryType
      },

      submitObject () {
        const submitObject = {
          ips: this.hostObject.host.ips,
          domainName: this.hostObject.host.domainName,
          maintainer: this.hostObject.host.maintainer,
          registryId: this.hostObject.host.registryId,
          clientId: this.hostObject.host.clientId,
          addOnFields: this.hostObject.host.addOnFields,
          ...this.isCreate ? {} : {id: this.hostObject.host.id}
        }

        if (this.hostObject.editProviderChain) {
          submitObject.providerChainSpec =
            this.hostObject.host.providerChainSpec

          submitObject.providerChainType =
            this.hostObject.host.providerChainType
        }

        return submitObject
      }
    },

    watch: {
      hostObject: {
        handler (newValue) {
          if (this.isCreate) {
            this.storeData ({
              host: this.hostObject.host,
              editProviderChain: this.hostObject.editProviderChain,
              addOnFieldData: this.hostObject.addOnFieldData
            })
          }

          if (this.defaultHost && !_isEqual (newValue.host, this.defaultHost)) {
            this.$emit ('unsavedChanges', true)
          } else {
            this.$emit ('unsavedChanges', false)
          }
        },
        deep: true
      },

      isChildHost (newValue) {
        if (!newValue) {
          this.savedIps = this.hostObject.host.ips
          this.hostObject.host.ips = []
        } else {
          this.hostObject.host.ips = this.savedIps
        }
      }
    },

    created () {
      if (!this.isCreate) {
        this.populateHostObject (this.hostData)
      }
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      ...mapMutations ({
        showError: 'notification/setError',
        storeData: 'create/setHostCreateData'
      }),

      /**
       * Store the registry ID after a registry has been selected.
       *
       * @param {String} registryId     the ID of the selected registry
       */
      onRegistrySelected (registryId) {
        this.hostObject.host.registryId = registryId
      },

      /**
       * Load host create data from store or default if create new
       */
      onCreateUpdateComponentLoaded () {
        if (this.isCreate) {
          const clientId = this.hostObject.host.clientId
          const registryId = this.hostObject.host.registryId
          this.hostObject.host = this.hostCreateData.host ||
            getDefaultHostData ()

          if (!this.hostObject.host.clientId) {
            this.hostObject.host.clientId = clientId
          }

          if (!this.hostObject.host.registryId) {
            this.hostObject.host.registryId = registryId
          }

          this.hostObject.editProviderChain =
            this.hostCreateData.editProviderChain ||
            DefaultEditProviderChain

          this.hostObject.addOnFieldData = this.hostCreateData.addOnFieldData
        }
      },

      /**
       * Load default provider chain when a new client has been selected.
       */
      onClientSelected (clientId) {
        if (clientId !== this.hostObject.host.clientId) {
          this.hostObject.host.clientId = clientId
        }
      },

      /**
       * Create a new Host or update a host on the server side
       *
       * clear the host form data in case of success, display error otherwise
       */
      async onSubmit () {
        // TODO, FIXME: this is a workaround for https://github.com/vuetifyjs/vuetify/issues/5203
        // (if submitted directly from combobox, then the combobox value is not updated yet, so wait...)
        // This workaround can be reverted as soon as the issue is resolved and the Vuetify library is updated
        setTimeout (() => {
          this.$v.$touch ()
          if (this.validData) {
            this.isLoading = true
            this.orderProcessingResponse = null

            this.fetchData ({
              op: `host/${this.isCreate ? 'create' : 'update'}`,
              params: {hostData: this.submitObject},
              cb: data => {
                this.orderProcessingResponse = null
                const registryId = this.hostObject.host.registryId

                if (this.isCreate) {
                  this.onReset ()
                }

                this.$emit ('unsavedChanges', false)
                this.$emit ('success', {
                  handle: data.handle,
                  registry: registryId
                })
              },
              cbError: data => {
                if (data.errors) {
                  this.orderProcessingResponse = data.errors
                } else {
                  this.showError (errorTranslator (data))
                }

                this.$emit ('failure')
              },
              cbFinal: () => {
                this.isLoading = false
              }
            })
          }
        })
      },

      /**
       * Reset the hostcobject data
       */
      onReset () {
        this.clearFormData ()
        this.orderProcessingResponse = null
        this.storeData (this.hostObject)
      },

      clearFormData () {
        this.isChildHost = false
        this.hostObject.editProviderChain = false
        this.$refs.providerchainComponent.onReset ()
        const providerChainSpec = this.hostObject.host.providerChainSpec
        const registryId = this.hostObject.host.registryId
        const clientId = this.hostObject.host.clientId
        this.hostObject.host = getDefaultHostData ()

        this.hostObject.host.registryId = registryId
        this.hostObject.host.clientId = clientId
        this.hostObject.host.providerChainSpec = providerChainSpec
        this.hostObject.addOnFieldData = getAddOnFieldData ()
      },

      /**
       * pupulate the host object for editing
       */
      populateHostObject (dataObject) {
        this.hostObject = Object.assign ({}, dataObject)
        const {handle, registryType} = this.hostObject.host
        if (handle && registryType) {
          this.loadHost ({
            handle,
            registryType
          })
        }
      },

      populateAddOnFields () {
        this.hostObject.addOnFieldData = objectToKeyValueArray (
          this.hostObject.host.addOnFields, k => removePrefix (k, '.'),
          v => v)
      },

      populateAddOnFieldData (data) {
        this.hostObject.host.addOnFields = keyValueArrayToObject (data)
      },

      /**
       * Load the host with the given parameters.
       *
       * @param {Object} params     the host load parameters
       */
      loadHost (params) {
        this.isLoadingHost = true

        this.fetchData ({
          op: 'host/load',
          params,
          cb: data => {
            this.hostObject.host = data.hostData
            this.hostObject.host.registryType = data.registryType
            this.populateAddOnFields ()

            if (!this.isCreate) {
              const states = data.viewData.states

              if (states.length && isUpdateProhibited (states)) {
                this.showError (this.$t ('edit.prohibited'))
                this.$router.replace ({
                  name: 'host.view',
                  params: {
                    registry: data.hostData.registryId,
                    handle: data.hostData.handle
                  }
                })
              }
            }
          },
          cbFinal: () => {
            this.isLoadingHost = false
            this.loaded = true
            this.savedIps = this.hostObject.host.ips
            if (this.hostObject.host.ips.length > 0) {
              this.isChildHost = true
            }
            this.defaultHost = _cloneDeep (this.hostObject.host)
          }
        })
      }
    }
  }
</script>
